<!--  羽毛球排期  -->
<template>
  <div class="badminton-schedule" v-loading="loading">
    <div class="badminton-schedule__header">
      <el-form :model="queryInfo" inline>
        <el-form-item label="校区">
          <el-radio-group ref="radioRef" v-model="queryInfo.campusId" :disabled="isEditing" @change="renderTable">
            <el-radio-button v-for="{ campusName, id } in campusList" :key="id" :label="id">{{ campusName }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="queryInfo.day" value-format="yyyy-MM-dd" type="date" format="yyyy-MM-dd"
            :disabled="isEditing" @change="renderTable" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="isEditing" class="mb-1 font-right" style="overflow: hidden">
      <el-button-group class="mr-1" v-if="isEditor">
        <el-button type="warning" plain @click="setOffFlag(1)">一键禁用
        </el-button>
        <el-button type="success" plain @click="setOffFlag(0)">一键启用
        </el-button>
      </el-button-group>
      <el-button @click="cancel">取消</el-button>
      <el-button v-if="isEditor" type="primary" @click="save">保存</el-button>
    </div>
    <div v-else class="mb-1 font-right">
      <el-button v-if="isEditor" type="primary" @click="isEditing = true">编辑
      </el-button>
    </div>
    <el-scrollbar style="height:calc(100% - 80px)">
      <div v-if="scheduleList.length" class="schedule-grid">
        <div class="schedule-grid__header flex">
          <div class="schedule-grid__label font-center" v-for="(item, index) in scheduleList" :key="index">
            <b class="room-name">{{ item.badmintonRoomName }}</b>
            <template v-if="item.badmintonScheduleVos.length && isEditing && isEditor">
              <el-button-group class="ml-1 room-btn" size="mini">
                <el-tooltip class="item" effect="dark" content="列禁用" placement="top-start">
                  <el-button size="mini" icon="el-icon-turn-off" type="warning" plain @click="switchColumn(1, item)">
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="列启用" placement="top-start">
                  <el-button size="mini" icon="el-icon-open" type="success" plain @click="switchColumn(0, item)" />
                </el-tooltip>
              </el-button-group>
            </template>
          </div>
        </div>
        <div class="schedule-grid__body flex-column">
          <div class="schedule-grid__wrap  flex schedule-grid__body-top">
            <div class="schedule-grid__column" v-for="(item, index) in scheduleList" :key="index">
              <div v-for="subItem in item.badmintonScheduleVos"  :class="['schedule-grid__item', 'flex-column', { 'is-editing': isEditing },
              {'is-order':subItem.orderFlag},
              {'is-disabled':subItem.offFlag},
              {'is-on':subItem.offFlag===0}]"
                :key="subItem.id">
                <template v-if="subItem.startTimeStr && subItem.endTimeStr">
                  <div class="time-mark font-center mb-1">{{ subItem.startTimeStr }}-{{ subItem.endTimeStr }}
                    <i v-if="subItem.orderFlag" class="el-icon-success fr mr-1" title="已被预约"></i>
                  </div>
                  <el-switch v-if="isEditing" v-model="subItem.offFlag" class="mb-1" :active-value="0" active-text="启用"
                    active-color="var(--color-success)" :inactive-value="1" inactive-text="禁用"
                    @change="handleSwitch($event, subItem)" />
                  <template v-else>
                    <el-tag v-show="subItem.offFlag" type="info">禁用</el-tag>
                    <el-tag v-show="subItem.offFlag === 0" type="success">启用</el-tag>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {
  cellBadmintonScheduleByManagerApi,
  cellBadmintonScheduleApi,
  editBatchBadmintonOffFlagApi
} from '@/api/student-manage/badminton-schedule-api'
import { cloneDeep } from 'lodash'
import store from '@/store'

export default {
  name: 'badmintonSchedule',
  data() {
    return {
      isEditing: false,
      isAdmin: false,
      campusList: [], // 校区list
      scheduleList: [], // 球场排期表
      initDataList: [], // 存初始化数据
      queryInfo: {
        campusId: null,
        day: null,
        orderQueryFlag: 1 // 用于获取格式是否已被预约
      },
      isEditor: false, // 编辑状态禁止跳转切换
      loading: false, // 遮罩
      curTab: '1',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < (Date.now() - 24 * 60 * 60 * 1000)
        }
      }
    }
  },
  async mounted() {
    const _permission = store.getters['login/getPermission']

    this.isEditor = this.$store.state.login.permissionTable.includes('badminton:schedule:edit')
    this.isAdmin = !!(this.$store.state.login.isAdmin || (_permission.includes('badminton:schedule:edit')))
    await this.getCampusList()
    await this.renderTable()
  },
  methods: {
    async renderTable() {
      try {
        const _today = this.$moment().format('YYYY-MM-DD')
        if (!this.queryInfo.day) {
          this.queryInfo.day = _today
        }

        // 除了非管理员，无法编辑外，非未来日期也不可编辑，但可以查看
        this.isEditor = this.isAdmin && this.$moment(this.queryInfo.day).isAfter(this.$moment())
        if (!this.queryInfo.campusId) {
          this.queryInfo.campusId = this.campusList[0].id
        }
        let res
        if (this.isAdmin) {
          // 超级管理员
          res = await cellBadmintonScheduleApi(this.queryInfo)
        } else {
          // 按分配的球场
          res = await cellBadmintonScheduleByManagerApi(this.queryInfo)
        }
        this.scheduleList = res?.data?.badmintonRoomScheduleVos || []
        if (res.data) {
          const arr = []
          res.data.badmintonRoomScheduleVos.forEach(column => {
            column.badmintonScheduleVos.forEach(subItem => {
              arr.push(subItem)
            })
          })
          this.initDataList = cloneDeep(arr)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 获取校区
    async getCampusList() {
      try {
        const { data } = await this.$http.getCampusListQuery({})
        this.campusList = data
      } catch (e) {
        console.error(e)
      }
    },
    cancel() {
      this.isEditing = false
      this.renderTable()
    },
    handleSwitch(val, subItem) {
      if (val && subItem.orderFlag) {
        this.$confirm('该时间段已被预约，是否确认禁用?', '确认信息').then().catch(() => {
          subItem.offFlag = 0
        })
      }
    },
    // 启用/禁用列
    switchColumn(status, column) {
      let hasOrderFlag = column.badmintonScheduleVos.filter(item => item.orderFlag)
      hasOrderFlag = hasOrderFlag.map(item => `${item.startTimeStr}-${item.endTimeStr}`).join(',')
      if (status && hasOrderFlag) {
        this.$confirm(`${column.badmintonRoomName}以下时间段已被预约：${hasOrderFlag}，您确定要禁止${this.queryInfo.day}的所有预约吗？`).then(() => {
          column.badmintonScheduleVos.forEach(item => {
            item.offFlag = status
          })
        })
      } else {
        this.$confirm(`是否确认${status ? '禁用' : '启用'}整列？`).then(() => {
          column.badmintonScheduleVos.forEach(item => {
            item.offFlag = status
          })
        })
      }
    },
    // 开启/禁用当日全部
    setOffFlag(status) {
      let hasOrderFlag = []
      this.scheduleList.forEach(col => {
        hasOrderFlag = hasOrderFlag.concat(col.badmintonScheduleVos.filter(item => item.orderFlag))
      })
      hasOrderFlag = hasOrderFlag.map(item => `${item.badmintonRoomName}(${item.startTimeStr}-${item.endTimeStr})`).join(',')
      if (status && hasOrderFlag) {
        this.$confirm(`以下场地和时间段已被预约：${hasOrderFlag}，是否确认禁用当日所有球场？`).then(() => {
          this.scheduleList.forEach(({ badmintonScheduleVos }) => {
            badmintonScheduleVos.forEach(subItem => {
              subItem.offFlag = status
            })
          })
        })
      } else {
        this.$confirm(`是否确认${status ? '禁用' : '启用'}当日所有球场？`).then(() => {
          this.scheduleList.forEach(({ badmintonScheduleVos }) => {
            badmintonScheduleVos.forEach(subItem => {
              subItem.offFlag = status
            })
          })
        })
      }
    },
    save() {
      this.$confirm(`是否确认${status ? '禁用' : '启用'}整列？`).then(async () => {
        try {
          this.loading = true
          const result = []

          this.scheduleList.forEach(column => {
            column.badmintonScheduleVos.forEach(item => {
              const isChanged = this.initDataList.find(i => i.badmintonRoomId === item.badmintonRoomId && i.startTime === item.startTime && i.endTime === item.endTime)
              if (isChanged.offFlag !== item.offFlag) {
                const target = {
                  day: item.day,
                  id: item.id,
                  badmintonRoomId: item.badmintonRoomId,
                  endTime: item.endTime,
                  startTime: item.startTime,
                  offFlag: item.offFlag
                }
                result.push(target)
              }
            })
          })
          if (!result.length) {
            this.$message.error('表格未修改！')
            return
          }
          await editBatchBadmintonOffFlagApi({ badmintonRoomSchedules: result })
          this.$message.success('修改成功')
          this.isEditing = false
          await this.renderTable()
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }).catch()
    }
  }
}
</script>
<style lang="scss">
.badminton-schedule {
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
  min-width: 760px;
  min-height: 400px;
  overflow: hidden;
  height: calc(100vh - var(--header-height));

  .badminton-schedule__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .schedule-grid__label {
    .room-name {
      font-size: 18px;
      line-height: 60px;
    }

    .room-btn {
      font-size: 16px;
    }
  }

  .schedule-grid .schedule-grid__column {
    justify-content: flex-start;

    .schedule-grid__item {
      flex: unset;
      justify-content: flex-start;
      height: 90px;
      border-bottom: 1px solid #DCDFE6;
      &.is-on{
        background: #f0f9ec;
        .time-mark{
          background: #87cf61;
          color: #fff;
        }
      }
      &.is-disabled{
        background: #f4f4f5;
        .time-mark{
          background: #e8e8e8;
        }
      }
    }

    .time-mark {
      width: 100%;
      color: #638391;
      padding: 6px 0;
      background: #ebf4f9;

      .el-icon-success {
        color: var(--color-success);
      }
    }
  }
}
</style>
